import React, { useState, Fragment } from 'react';
import PageInfo from 'src/components/page-info';
import Navbar from 'src/sections/common/navbar';
import Hero from 'src/sections/homepage/hero';
import About from 'src/sections/homepage/about';
import Movement from 'src/sections/homepage/movement';
import Testimonials from 'src/sections/homepage/testimonials';
import Location from 'src/sections/homepage/location';
import Footer from 'src/sections/common/footer';
import Modal from 'src/components/modal';
import EmailForm from 'src/components/email-form';
import Cross from 'src/components/cross';

const Home = () => {
    const [isSubscribing, setIsSubscribing] = useState(false);

    const onSubscribe = () => setIsSubscribing(true);

    const onClose = () => setIsSubscribing(false);

    return (
        <Fragment>
            <PageInfo name="home" />
            <Navbar onSubscribe={onSubscribe} />
            <Hero />
            <About />
            <Movement onSubscribe={onSubscribe} />
            <Testimonials />
            <Location />
            <Footer />
            <Modal isOpen={isSubscribing} onClose={onClose}>
                <Cross onClick={onClose} />
                <EmailForm />
            </Modal>
        </Fragment>
    );
};

export default Home;
