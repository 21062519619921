import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Leaflet from 'leaflet';
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';

import data from 'src/data.json';

const TILE_URL = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';

const Container = styled(MapContainer)`
    width: 100%;
    height: 400px;
`;

const CENTER = [data.location.coordinates.lat, data.location.coordinates.lng];

const mapSettings = {
    center: CENTER,
    zoom: data.location.zoom,
    scrollWheelZoom: false
};

const Map = () => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    let icon;

    if (isClient) {
        icon = new Leaflet.Icon({
            iconUrl: require('src/assets/images/favicon.png'),
            iconSize: [25, 25], // size of the icon
            iconAnchor: [12, 12], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -15] // point from which the popup should open relative to the iconAnchor
        });
    }

    const isMobile = isClient && /Mobi|Android/i.test(navigator.userAgent);

    return (
        isClient && (
            <Container dragging={!isMobile} touchZoom={isMobile} {...mapSettings}>
                <Marker position={CENTER} icon={icon}>
                    <Popup>
                        <a href={data.location.link}>{data.location.address}</a>
                    </Popup>
                </Marker>
                <TileLayer url={TILE_URL} />
            </Container>
        )
    );
};

export default Map;
