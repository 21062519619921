import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import Button from 'src/components/button';

import data from 'src/data.json';
import styles from 'src/styles/variables.json';
import motto from 'src/assets/images/motto.png';

import 'pure-react-carousel/dist/react-carousel.es.css';

const Container = styled.div`
    width: 100%;
    background: ${styles.main.backgroundColor};
    padding: 60px 0 10px;
    margin-bottom: 60px;
`;

const Motto = styled.img`
    max-width: 90%;
    width: 300px;
    margin: 0 auto 60px;
    display: block;
`;

const Movement = ({ onSubscribe }) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    return (
        <Container>
            <Motto src={motto} alt="It's not fitness. It's life!" />
            <CarouselProvider
                naturalSlideWidth={330}
                naturalSlideHeight={220}
                totalSlides={data.carousel.length}
                visibleSlides={isClient ? Math.floor(window.innerWidth / 330) + 1 : 1}
                isIntrinsicHeight
                infinite
                interval={3000}
                isPlaying
                step={2}
            >
                <Slider>
                    {data.carousel.map((src, index) => (
                        <Slide key={index} index={index} style={{ width: '330px' }}>
                            <img src={src} alt="" />
                        </Slide>
                    ))}
                </Slider>
            </CarouselProvider>
                <Button onClick={onSubscribe} width="220" invert>
                    Join the movement
                </Button>
        </Container>
    );
};

export default Movement;
