import styled from 'styled-components';
import styles from 'src/styles/variables.json';

const Button = styled.button`
    display: block;
    position: relative;
    color: ${({ invert }) => (invert ? styles.main.backgroundColor : styles.main.color)};
    text-transform: uppercase;
    font-size: 1rem;
    text-align: center;
    z-index: 1;
    text-decoration: none;
    outline: none;
    border: none;
    margin: 60px auto;
    cursor: pointer;

    &:before {
        content: '';
        height: 40px;
        width: ${({ width }) => width}px;
        position: absolute;
        top: -10px;
        left: calc(50% - ${({ width }) => width / 2}px);
        background: ${({ invert }) => (invert ? styles.main.color : styles.main.backgroundColor)};
        transform: skew(-15deg);
        z-index: -1;
    }
`;

export default Button;
