import styled from 'styled-components';
import styles from 'src/styles/variables.json';

const Paragraph = styled.p`
    text-align: center;
    margin: 6px auto;
    color: ${({ error }) => (error ? 'red' : styles.main.backgroundColor)};
`;

export default Paragraph;
