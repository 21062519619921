import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import favicon from 'src/assets/images/favicon.png';

const PageInfo = ({ name }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        home {
                            title
                            description
                            author
                            url
                            image
                        }
                    }
                }
            }
        `
    );

    const { title, description, url, image, author } = site.siteMetadata[name];

    return (
        <Helmet titleTemplate="%s">
            <title>{title}</title>
            <html lang="pt" />
            <link rel="shortcut icon" type="image/png" href={favicon} />
            <meta name="title" property="og:title" content={title} />
            <meta name="description" property="og:description" content={description} />
            <meta name="type" property="og:type" content="website" />
            <meta name="image" property="og:image" content={image} />
            <meta name="image:width" property="og:image:width" content={544} />
            <meta name="image:height" property="og:image:height" content={180} />
            <meta property="og:url" content={url} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:creator" content={author} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:site:domain" content="www.made2move.pt" />
            <meta name="twitter:url" content={url} />
        </Helmet>
    );
};

export default PageInfo;
