import styled from 'styled-components';

import styles from 'src/styles/variables.json';

const Link = styled.a`
    padding: 5px 10px;
    font-size: 0.9rem;
    font-weight: ${({ invert }) => (invert ? 'bold' : 'normal')};
    text-transform: uppercase;
    color: ${({ invert }) => (invert ? styles.main.color : styles.main.backgroundColor)};
    background: ${({ invert }) => (invert ? 'rgb(75, 75, 75)' : styles.main.color)};
    text-align: center;
    margin-left: 15px;
    position: relative;
    cursor: pointer;
    text-decoration: none;

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 4px;
        left: 10%;
        right: 100%;
        border-bottom: 2px solid
            ${({ invert }) => (invert ? styles.main.color : styles.main.backgroundColor)};
        transition: right 0.5s;
    }

    &:hover:after {
        right: 10%;
        left: 10%;
    }

    @media only screen and (max-width: ${styles.breakpoints.small}) {
        margin: 15px 0;
    }
`;

export default Link;
