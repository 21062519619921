import React, { Fragment } from 'react';
import styled from 'styled-components';
import Container from 'src/components/container';

import styles from 'src/styles/variables.json';
import data from 'src/data.json';
import logo from 'src/assets/images/logo.png';

const Footer = styled.footer`
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    background: ${styles.main.backgroundColor};
    color: ${styles.main.color};
`;

const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: wrap;

    @media only screen and (max-width: ${styles.breakpoints.small}) {
        flex-direction: column-reverse;
    }
`;

const Section = styled.div`
    width: 250px;
    text-align: center;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
`;

const Logo = styled.img`
    max-width: 100%;
    margin: 6px auto;
`;

const Socials = styled.div`
    text-align: center;
`;

const SocialLink = styled.a`
    width: 25px;
    display: inline-block;
    margin: 0 5px;
`;

const Title = styled.h6`
    font-size: 20px;
    margin: 5px 0;
`;

const List = styled.ul`
    margin: 10px 0;
`;

const ListItem = styled.li`
    margin: 10px 0;
    font-size: 14px;
`;

const Trademark = styled.p`
    text-align: center;
    font-size: 12px;
    color: ${styles.main.color};
    background: ${styles.main.backgroundColor};
    margin: 0;
    padding-bottom: 15px;
`;

const Content = () => (
    <Fragment>
        <Footer>
            <StyledContainer>
                <Section>
                    <Logo alt="Made2Move logo" src={logo} />
                    <Socials>
                        {data.footer.socials.map(({ name, logo, link }) => (
                            <SocialLink key={name} href={link} target="_blank">
                                <Logo alt={name} src={logo} />
                            </SocialLink>
                        ))}
                    </Socials>
                    <Trademark>© 2021 by Made2Move.</Trademark>
                </Section>
                <Section>
                    <Title>Horário</Title>
                    <List>
                        {data.footer.openingHours.map(hour => (
                            <ListItem key={hour}>{hour}</ListItem>
                        ))}
                    </List>
                </Section>
                <Section>
                    <Title>Contactos</Title>
                    <List>
                        {data.footer.contacts.map(({ label, value }) => (
                            <ListItem key={label}>
                                <b>{label}:</b> {value}
                            </ListItem>
                        ))}
                    </List>
                </Section>
            </StyledContainer>
        </Footer>
    </Fragment>
);

export default Content;
