import React from 'react';
import styled from 'styled-components';

import styles from 'src/styles/variables.json';

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    display: block;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    background: ${styles.main.color};
    padding: 25px;
    position: fixed;
    max-height: 100vh;
`;

const Modal = ({ isOpen, onClose, children }) =>
    isOpen && (
        <Background onClick={onClose}>
            <Container onClick={event => event.stopPropagation()}>{children}</Container>
        </Background>
    );

export default Modal;
