import React, { Fragment } from 'react';
import Paragraph from './paragraph';
import data from 'src/data.json';

const ErrorPage = () => (
    <Fragment>
        <Paragraph error>Houve um erro ao enviar a sua mensagem!</Paragraph>
        <Paragraph>
            Por favor volte a tentar mais tarde, ou entre em contacto connosco através do nosso
            email:
        </Paragraph>
        <Paragraph>{data.footer.contacts[0].value}</Paragraph>
    </Fragment>
);

export default ErrorPage;
