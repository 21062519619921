import React from 'react';
import styled from 'styled-components';
import Container from 'src/components/container';

import styles from 'src/styles/variables.json';
import data from 'src/data.json';

const StyledContainer = styled(Container)`
    margin-bottom: 60px;
    box-sizing: border-box;

    @media only screen and (max-width: ${styles.breakpoints.small}) {
        margin-bottom: 0px;
        padding: 25px;
    }
`;

const Title = styled.h3`
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 25px;
`;

const Paragraph = styled.p`
    margin-bottom: 25px;
    font-size: 1rem;
`;

const About = () => (
    <StyledContainer id="sobre">
        <Title>We're Made2Move,</Title>
        {data.about.map(text => (
            <Paragraph key={text}>{text}</Paragraph>
        ))}
    </StyledContainer>
);

export default About;
