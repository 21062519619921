import React, { Fragment } from 'react';
import Paragraph from './paragraph';

const SuccessPage = () => (
    <Fragment>
        <Paragraph>A sua mensagem foi enviada com sucesso!</Paragraph>
        <Paragraph>Desde já agradecemos o seu interesse por ter chegado até aqui!</Paragraph>
        <Paragraph>Dê-nos entre 24 a 48 horas para responder.</Paragraph>
    </Fragment>
);

export default SuccessPage;
