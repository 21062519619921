import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'src/components/button';

const Form = styled.form`
    width: 100%;
    margin-top: 15px;
`;

const Label = styled.label`
    font-weight: bold;
`;

const Input = styled.input`
    width: 100%;
    outline: none;
    margin-bottom: 20px;
    height: 30px;
`;

const Textarea = styled.textarea`
    width: 100%;
    height: 100px;
    outline: none;
`;

const Legend = styled.p`
    text-align: center;
    font-size: 0.7rem;
    color: ${({ error }) => (error ? 'red' : '#1d1d1d')};
`;

const EmailForm = ({ onSubmit }) => {
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);

    const onChange = setState => event => setState(event.target.value);

    const doSubmit = event => {
        event.preventDefault();

        if (!name || !contact || !message) {
            setError('Por favor preencha todos os campos antes de enviar a mensagem');
            return;
        }

        console.log(name, contact, message);
        setError(null);
        onSubmit({ name, contact, message });
    };

    return (
        <Form onSubmit={doSubmit}>
            <Label>Nome:</Label>
            <Input value={name} onChange={onChange(setName)} />
            <Label>Contacto:</Label>
            <Input value={contact} onChange={onChange(setContact)} />
            <Label>Em que podemos ajudar?</Label>
            <Textarea value={message} onChange={onChange(setMessage)} />
            <Legend error={!!error}>{error ? error : '(Todos os campos são obrigatórios)'}</Legend>
            <Button width="120" onClick={doSubmit}>
                Enviar
            </Button>
        </Form>
    );
};

export default EmailForm;
