import React from 'react';
import styled from 'styled-components';

import styles from 'src/styles/variables.json';

import background from 'src/assets/images/hero.jpg';
import privateTrainging from 'src/assets/images/private-training.png';
import made2move from 'src/assets/images/by-made2move.png';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;
    background: url('${background}');
    background-position: center;
    background-size: cover;
    margin-bottom: 60px;
    background-attachment: fixed;

    @media only screen and (max-width: ${styles.breakpoints.small}) {
        background-position: right;
    }
`;

const PrivateTraining = styled.img`
    width: 420px;
    max-width: 100%;
    position: absolute;
    top: calc(40% - 200px);
    left: calc(50% - 210px);

    @media only screen and (max-width: ${styles.breakpoints.small}) {
        width: 280px;
        top: calc(50% - 240px);
        left: calc(50% - 140px);
    }
`;

const Made2Move = styled.img`
    position: absolute;
    width: 420px;
    left: calc(50% - 210px);
    bottom: 50px;

    @media only screen and (max-width: ${styles.breakpoints.small}) {
        width: 280px;
        left: calc(50% - 140px);
    }
`;

const Hero = () => (
    <Container>
        <PrivateTraining alt="private training" src={privateTrainging} />
        <Made2Move alt="by Made2Move" src={made2move} />
    </Container>
);

export default Hero;
