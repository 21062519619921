import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Container from 'src/components/container';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';

import data from 'src/data.json';

const Title = styled.h3`
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 25px;
    text-align: center;
`;

const Comment = styled(Slide)`
    text-align: center;
    padding: 25px;
    box-sizing: border-box;
`;

const OpenQuote = styled.span`
    quotes: '“' '”' '‘' '’';
    &:before {
        content: open-quote;
        font-size: 64px;
        font-weight: bold;
        line-height: 64px;
    }
`;

const CloseQuote = styled.span`
    quotes: '“' '”' '‘' '’';
    &:after {
        content: close-quote;
    }
`;

const Quote = styled.p`
    text-align: justify;
`;

const Author = styled.p`
    text-align: right;
    font-style: italic;
`;

const Testimonials = () => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    return (
        <Container id="testemunhos">
            <Title>Testemunhos</Title>
            <CarouselProvider
                totalSlides={data.testimonials.length}
                visibleSlides={isClient && window.innerWidth >= 640 ? 2 : 1}
                isIntrinsicHeight
                infinite
                interval={6000}
                step={1}
                lockOnWindowScroll={true}
            >
                <DotGroup />
                <Slider>
                    {data.testimonials.map(({ author, comment }, index) => (
                        <Comment key={author} index={index}>
                            <OpenQuote />
                            {comment.map((quote, index) => (
                                <Quote key={quote}>
                                    {quote}
                                    {index === comment.length - 1 && <CloseQuote />}
                                </Quote>
                            ))}
                            <Author>{author}</Author>
                        </Comment>
                    ))}
                </Slider>
            </CarouselProvider>
        </Container>
    );
};

export default Testimonials;
