import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Container from 'src/components/container';
import Form from './form';
import SuccessPage from './success-page';
import ErrorPage from './error-page';

import styles from 'src/styles/variables.json';
import logo from 'src/assets/images/favicon.png';

const STATES = {
    FILLING: 'filling',
    ERROR: 'error',
    SUCCESS: 'success'
};

const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
`;

const Title = styled.h3`
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
    color: ${styles.main.backgroundColor};
`;

const Logo = styled.img`
    width: 25px;
    position: relative;
    top: 2px;
`;

const EmailForm = () => {
    const [state, setState] = useState(STATES.FILLING);

    const onSubmit = async data => {
        try {
            await axios.post('/.netlify/functions/sendgrid', data);
            setState(STATES.SUCCESS);
        } catch (err) {
            setState(STATES.ERROR);
        }
    };

    return (
        <StyledContainer>
            <Title>
                Join the
                <br />
                m<Logo src={logo} alt="o" />
                vement
            </Title>
            {state === STATES.FILLING ? (
                <Form onSubmit={onSubmit} />
            ) : state === STATES.SUCCESS ? (
                <SuccessPage />
            ) : (
                <ErrorPage />
            )}
        </StyledContainer>
    );
};

export default EmailForm;
