import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import Container from 'src/components/container';
import Cross from 'src/components/cross';
import Link from './link';

import hamburguer from 'src/assets/images/menu.png';
import styles from 'src/styles/variables.json';
import logo from 'src/assets/images/logo-dark.png';

const StyledCross = styled(Cross)`
    position: absolute;
    top: 20px;
    left: calc(100% - 40px);
`;

const Nav = styled.nav`
    height: 50px;
    width: 100%;
    background: ${styles.main.color};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;

    @media only screen and (max-width: ${styles.breakpoints.small}) {
        position: fixed;
        width: 100%;
        height: 100%;
        left: ${({ show }) => (show ? 0 : 100)}%;
        transition: left 1s;
    }
`;

const StyledContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    position: relative;

    @media only screen and (max-width: ${styles.breakpoints.small}) {
        flex-flow: column-reverse;
        height: 100%;
        text-align: center;
    }
`;

const LinkList = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    box-sizing: border-box;
    @media only screen and (max-width: ${styles.breakpoints.small}) {
        flex-flow: column;
        margin-top: 60%;
    }
`;

const Logo = styled.img`
    height: 20px;
    padding: 15px;
    @media only screen and (max-width: ${styles.breakpoints.small}) {
        width: 140px;
        height: unset;
        display: block;
        margin: 0 auto 85%;
    }
`;

const OpenButton = styled.button`
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1000;
    border: none;
    outline: none;
    background: transparent;
`;

const Hamburguer = styled.img`
    background: transparent;
`;

const Navbar = ({ onSubscribe }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        setIsMobile(window.innerWidth < styles.breakpoints.small.slice(0, 3));
    }, []);

    const close = () => setOpen(false);

    return (
        <Fragment>
            {isMobile && (
                <OpenButton onClick={() => setOpen(true)}>
                    <Hamburguer src={hamburguer} />
                </OpenButton>
            )}
            <Nav show={isOpen}>
                <StyledContainer>
                    {isMobile && <StyledCross onClick={() => setOpen(false)} />}
                    <Logo src={logo} alt="Made2Move" />
                    <LinkList>
                        <Link href="#sobre" onClick={close}>Sobre Nós</Link>
                        <Link href="#testemunhos" onClick={close}>Testemunhos</Link>
                        <Link onClick={onSubscribe} invert>
                            Join the movement
                        </Link>
                    </LinkList>
                </StyledContainer>
            </Nav>
        </Fragment>
    );
};

export default Navbar;
