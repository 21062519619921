import styled from 'styled-components';

import styles from 'src/styles/variables.json';

const Cross = styled.span`
    display: block;
    background: ${styles.main.backgroundColor};
    height: 20px;
    position: relative;
    width: 2px;
    opacity: 0.5;
    transform: rotate(45deg);
    cursor: pointer;
    left: 100%;

    &:after {
        background: ${styles.main.backgroundColor};
        content: '';
        height: 2px;
        left: -9px;
        position: absolute;
        top: 9px;
        width: 20px;
    }

    &:hover {
        opacity: 1;
    }

    @media only screen and (max-width: ${styles.breakpoints.small}) {
        top: 35px;
    }
`;

export default Cross;
